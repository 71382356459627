import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Container, Form } from 'react-bootstrap';
import { fetchModules, fetchThemes } from '../../../http/teacherApi';
import { Card } from 'antd';

//Силпабус студента
const StudentSilabusNM = observer((params /* { student } */) => {
    const { student } = useContext(Context)
    const _student = (params.student) ? params.student : student
    console.log("student NM: ",_student);
    

    const [modules, setModules] = useState([])
    const [themes, setThemes] = useState([])
    
    //    const [selectedSubject, setSelectedSubject] = useState(null)
    //    const [selectedModule, setSelectedModule] = useState({})

    useEffect(() => {
         loadData()
        if (!_student.selectedSubject) {
            _student.setSelectedSubject(_student?.studentSubjects[0]?.id)
            selectSubject(_student?.studentSubjects[0]?.id)
        } else {
            selectSubject(_student?.selectedSubject)
        }
    }, [])

    useEffect(() => {
        loadTableData()
    }, [_student.selectedModule])

    const loadData = async () => {
        console.log("LOAD", student.selectedSubject, student.selectedModule);
        //setSelectedSubject(student.studentSubjects[0]?.id)
        if (!_student.selectedSubject) {
            _student.setSelectedSubject(_student.studentSubjects[0]?.id)
            await fetchModules(_student.studentClass, _student.studentSubjects[0]?.id).then(data => setModules(data))
        } else {
            await fetchModules(_student.studentClass, _student.selectedSubject).then(data => setModules(data))
        }
        //student.setSelectedSubject(student.studentSubjects[0]?.id)
        //        await fetchModules(student.studentClass, student.studentSubjects[0]?.id).then(data => setModules(data))

        //setSelectedModule(student.studentModules[0])
        if (!_student.selectedModule?.id) {
            _student.setSelectedModule(_student.studentModules[0])
            await fetchThemes(_student.studentModules[0]?.id).then(data => setThemes(data))
        } else {

            await fetchThemes(_student.selectedModule.id).then(data => setThemes(data))
        }
console.log("load data finish");

        //       student.setSelectedModule(student.studentModules[0])
        //       await fetchThemes(student.studentModules[0]?.id).then(data => setThemes(data))
    }

    const loadTableData = async () => {
        if (_student.selectedModule?.id) {
            await fetchThemes(_student.selectedModule.id).then(data => setThemes(data))
        }
    }

    const selectSubject = async (subjectID) => {
        _student.setSelectedSubject(subjectID)
        //setSelectedSubject(subjectID)
        const data = await fetchModules(_student.studentClass, subjectID)
        setModules(data)
        //await selectModule(data[0].id)

        _student.setSelectedModule(data.find((modul) => modul.selected))

        await fetchThemes(data[0].id).then(data => setThemes(data))
    }

    const selectModule = async (moduleID) => {
        _student.setSelectedModule(modules.find(modul => modul.id == moduleID))
        await fetchThemes(moduleID).then(data => setThemes(data))
    }

    function click(theme) {
        _student.setSelectedTheme(theme)
        _student.setSelectedSubMenu({ id: 61 })
    }

    return (
        <Card>
            <Form className="mx-2">
                <Form.Select onChange={(e) => selectSubject(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={_student.selectedSubject || _student?.studentSubjects[0]?.id} aria-label="Default select example">
                    {_student.studentSubjects.map(subject =>
                        <option key={subject.id} value={subject.id}>{subject.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Form className="mx-2">
                <Form.Select onChange={(e) => selectModule(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={_student.selectedModule?.id || _student?.studentModules[0]?.id} aria-label="Default select example">
                    {modules.map(module =>
                        <option key={module.id} value={module.id}>{module.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Table striped bordered hover style={{ 'marginTop': '15px' }}>
                <thead>
                    <tr>
                        <th colSpan="100%">Модуль {_student.selectedModule?.index} : {_student.selectedModule?.name}</th>
                    </tr>
                    <tr>
                        <th colSpan="100%">Начало: {_student.selectedModule?.begin?.split('T')[0]}    Окончание: {_student.selectedModule?.end?.split('T')[0]}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Название темы</th>
                        <th>Дата</th>
                        {/* <th>Самооценка</th> */}
                        {/* <th>Оценка учителя</th> */}
                        <th>Оценка</th>
                    </tr>

                    {themes.map((theme, index) =>
                        <tr key={index}>
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => click(theme)}
                                key={'name' + theme.id}
                            >
                                {theme.selected ?
                                    <div class='btn btn-primary'>{theme.name}</div>
                                    //<div style={{color:"blue"}}>{theme.name}</div>
                                    :
                                    theme.name
                                }
                            </td>
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => click(theme)}
                                key={'end' + theme.id}
                            >
                                {/* {console.log("the",theme)} */}
                                {theme?.end?.split('T')[0]}
                            </td>
                            {/*      <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => click(theme)}
                                key={'sel' + theme.id}
                            >
                                {theme?.studentScore}
                            </td>
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => click(theme)}
                                key={'sco' + theme.id}
                            >
                                {theme?.teacherScore}
                            </td> */}
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => click(theme)}
                                key={'sco' + theme.id}
                            >
                                {theme?.score}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Card>
    );
});

export default StudentSilabusNM;