import { useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { admin, Context } from '../';
import { Row, Col } from 'react-bootstrap';
//import AdminBar from '../components/admin/AdminBar';
import AdminClassesTable from '../components/admin/AdminClassesTable';
import AdminLessons from '../components/admin/schedules/AdminLessons';

import AdminModulesTable from '../components/admin/AdminModulesTable';
import { fetchClasses, fetchSubjects, fetchWorkTypes, userList, fetchScheduleGrids } from '../http/adminApi';
import AdminModulesTableNM from '../components/admin/AdminModulesTableNM';
import ThemesTable from '../components/teacher/syllabus/ThemesTable';
import RoutesTable from '../components/teacher/syllabus/RoutesTable';
import DescriptionsTable from '../components/teacher/syllabus/DescriptionsTable';
import AdminBarSub from '../components/admin/AdminBarSub';
import AdminClasses from '../components/admin/scores/AdminClasses';
import AdminStudents from '../components/admin/scores/AdminStudents';
import AdminStudent from '../components/admin/scores/AdminStudent';
import AdminSetScore from '../components/admin/scores/AdminSetScore';
import AdminStudentSubjectStar from '../components/admin/stars/AdminStudentSubjectStar';
import AdminUsersTabs from '../components/admin/AdminUsersTabs';
import AdminRefsTabs, { refAdminRouter } from '../components/admin/refs/AdminRefsTabs';
import AdminEventsCalendar from '../components/admin/AdminEventsCalendar';
import AdminEventsTable from '../components/admin/AdminEventsTable';
import AdminParentMeetingTabs, { loader as loaderVisits, router as meetingRouter } from '../components/admin/parentmeeting/AdminParentMeetingTabs';
import Silabus from '../components/Silabus';



import { Navigate, Outlet } from 'react-router-dom';
import AdminStudentStars from '../components/admin/stars/AdminStudentStars';
import MenuBar from '../components/MenuBar';
import AdminEventsTable2, { actionEvents, destroyEvent, eventRouter, loaderEvents, newEvent } from '../components/admin/AdminEventsTable2';

export const fakeLoader = async () => {
  //console.log("fake loader");
  return null;
}

export const loader = async ({ request, params }) => {
  console.log("Admin loader");
  fetchSubjects().then(data => admin.setSubjects(data))
  fetchWorkTypes().then(data => admin.setWorkTypes(data))
  fetchClasses().then(data => admin.setClasses(data))
  userList('teacher').then(data => admin.setTeachers(data))
  userList('parent').then(data => admin.setParents(data))
  userList('student').then(data => admin.setStudents(data))
  fetchScheduleGrids().then(data => admin.setScheduleGrids(data))
  return null;
}


const Admin = () => {
  //  const data = userList()



  const { store } = useContext(Context)
  //  console.log("admin user role: ",user?.role);
  //const { admin } = useContext(Context)

  /*   const localizer = momentLocalizer(moment) // or globalizeLocalizer */
  useEffect(() => {
    store.setRole('ADMIN');
  
    //fetchBranches().then(data => admin.setBranches(data))
    // убрано   fetchLessonTypes().then(data => admin.setLessonTypes(data))
    // через admin для совместимости
    /*     fetchSubjects().then(data => admin.setSubjects(data))
    
        fetchWorkTypes().then(data => admin.setWorkTypes(data))
        fetchClasses().then(data => admin.setClasses(data))
        userList('teacher').then(data => admin.setTeachers(data))
        userList('parent').then(data => admin.setParents(data))
        userList('student').then(data => admin.setStudents(data))
        fetchScheduleGrids().then(data => admin.setScheduleGrids(data)) */
    // fetchSchedule(6).then(data => admin.setLessonsSchedule(data))
    //userList().then(data => admin.setUsers(data))
  }, [])

  return (
    <Row className='mt-2'>
      <Col md={2}>
        <MenuBar menu={sideMenu} />
      </Col>
      <Col md={9}>
        <AdminBarSub />
        <Outlet />
      </Col>
    </Row>
  );
};

const sideMenu = [
  { id: 18, name: "Пользователи", link: 'users' },
  /*             { id: 1, name: "Учителя" },
              { id: 17, name: "Тьюторы" },
              { id: 6, name: "Студенты" },
              { id: 7, name: "Родители" },
              { id: 10, name: "Администраторы" }, */
  { id: 2, name: "Классы", link: 'classesT' },
  { id: 3, name: "Расписание", link: 'lessons' },
  /* { id: 13, name: "Посещаемость" }, */
  /*  { id: 11, name: "Модули(модал)" }, */
  { id: 14, name: "Модули (архив)", link: 'modulesNM' },
  { id: 21, name: "Силабус", link: 'silabus' },
  { id: 4, name: "Ведомость", link: 'classes' },
  { id: 9, name: "Календарь событий (архив)", link: 'events' },
  { id: 22, name: "Календарь событий 2", link: 'events2' },
  { id: 20, name: "Визиты родителей", link: 'visits' },
  { id: 5, name: "Сообщения", link: 'messages' },
  /*  { id: 12, name: "Сетки расписания" }, */
  { id: 8, name: "Справочники", link: 'refs' },
  /* { id: 16, name: "Звезды", link: 'subject_star' }, */
  { id: 16, name: "Звезды 2", link: 'stars' },
  /*  { id: 15, name: "Субьектная звезда" }, */
  /* { id: 19, name: "Системный журнал", link: 'syslog' }, */
]
//        this._selectedSideMenu = { id: 18, name: "Пользователи" }

export const adminRouter = [
  { index: true, element: < Navigate to='users' /> },
  { path: 'users', element: < AdminUsersTabs />, loader: fakeLoader },
  { path: 'visits/*', element: < AdminParentMeetingTabs />, loader: loaderVisits, children: meetingRouter },
  { path: 'classesT', element: < AdminClassesTable />, loader: fakeLoader },
  { path: 'refs/*', element: < AdminRefsTabs />, loader: fakeLoader, children: refAdminRouter },
  { path: 'calendar', element: < AdminEventsCalendar />, loader: fakeLoader },
  { path: 'events', element: < AdminEventsTable />, loader: fakeLoader },
  { path: 'events2', element: < AdminEventsTable2 />, loader: loaderEvents, action: actionEvents, children: eventRouter },
  { path: 'lessons', element: < AdminLessons />, loader: fakeLoader },
  { path: 'modules', element: < AdminModulesTable />, loader: fakeLoader },
  { path: 'modulesNM', element: < AdminModulesTableNM />, loader: fakeLoader },
  { path: 'themes', element: < ThemesTable />, loader: fakeLoader },
  { path: 'routes', element: < RoutesTable />, loader: fakeLoader },
  { path: 'descriptions', element: < DescriptionsTable />, loader: fakeLoader },
  { path: 'classes', element: < AdminClasses />, loader: fakeLoader },
  { path: 'students', element: < AdminStudents />, loader: fakeLoader },
  { path: 'student', element: < AdminStudent />, loader: fakeLoader },
  { path: 'setscore', element: < AdminSetScore />, loader: fakeLoader },
  { path: 'subject_star', element: < AdminStudentSubjectStar />, loader: fakeLoader },
  { path: 'silabus', element: <Silabus />, loader: fakeLoader },
  { path: 'stars', element: < AdminStudentStars />, loader: fakeLoader },

];

export default observer(Admin);