import moment from 'moment'
//import 'moment/locale/ru'
import styled from 'styled-components'
import React, { useMemo, useState, useEffect, useContext } from 'react';

import { Calendar, Views, dayjsLocalizer, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';

//import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
//import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'; // if using DnD

//import events from '../events'
import * as dates from '../utils/dates'
import './navbar.css'
import { Context } from '..';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types'

import dayjs from 'dayjs'
dayjs.locale('ru')

const CheckboxContainer = styled.div`
div {
}
`
// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.import withDragAndDrop from 

const EventAgenda = ({ event }: any) => {
  return (
    <span>
      <em style={{ color: 'magenta' }}>{event.title}</em>
      <p>{event.desc}</p>
    </span>
  )
}
EventAgenda.propTypes = {
  event: PropTypes.object,
}

const MyCalendar = observer((props) => {

  //observer(function MyCalendar(props) {

  //  const DnDCalendar = withDragAndDrop(Calendar);
  //const localizer = momentLocalizer(moment);
  const localizer = dayjsLocalizer(dayjs);

  const { admin } = useContext(Context)
  const [events, setEvents] = useState<any[]>([])

  /* = [
    {
      start: moment().toDate(),
      end: moment()
        .add(1, "days")
        .toDate(),
      title: "Some title11"
    }
  ]
 */

  const format = "YYYY-MM-DD HH:mm"

  /*   useEffect(() => {
      admin.calendarEvents.map(event => setEvents([{start: moment(event.date, format).toDate(), end: moment(event.end, format).toDate(), title: event.name}, ...events]))
    }, []) */

  useEffect(() => {
    let tempEvents = new Array()
    admin.calendarEvents.map(event => {
      
      //console.log(moment(dayjs(event.date).format(), format).toDate());
      //console.log(dayjs(event.date).toDate());


      //      tempEvents = [...tempEvents, { id: event.id, start: moment(dayjs(event.date).format(), format).toDate(), end: moment(dayjs(event.end).format(), format).toDate(), title: event.name }]
      tempEvents = [...tempEvents, { id: event.id, start: dayjs(event.date).toDate(), end: dayjs(event.end).toDate(), title: event.name, color: event?.calendareventtype?.color }]
      //setEvents([{start: moment(event.date, format).toDate(), end: moment(event.end, format).toDate(), title: event.name}, ...events])
      //console.log("dssf", event, tempEvents);
    })
    console.log(tempEvents);
    //console.log("dssf", tempEvents);
    setEvents(tempEvents)
  }, [admin.calendarEvents])


  const lang = {
    'ru': {
      week: 'Неделя',
      work_week: 'Рабочая неделя',
      day: 'День',
      month: 'Месяц',
      previous: '<<',
      next: '>>',
      today: 'Сегодня',
      agenda: 'События',

      date: 'Дата',
      time: 'Время',
      event: 'Событие',
      allDay: 'Весь день',
      yesterday: 'Вчера',
      tomorrow: 'Завтра',

      noEventsInRange: 'Нет событий в этом периоде',

      showMore: (total: any) => `+${total} записей`,
    }
  }




  const ColoredDateCellWrapper = ({ children }: any) => {
    //console.log("children: ", children);

    return React.cloneElement(React.Children.only(children), {
      style: {
        //        backgroundColor: 'lightblue',
        backgroundColor: '#EEFFEE',
      },
    }
    )
  }

  const { components, messages, defaultDate, max, min, /* views */ } = useMemo(
    () => ({
      components: {
        //agenda: EventAgenda,
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      event: Event,
      defaultDate: dayjs().format(), // new moment(moment.now()).toDate(),
      max: new Date(2050, 0, 1, 21, 0, 0, 0),
      min: new Date(1972, 0, 1, 7, 0, 0, 0),
      //views: Object.keys(Views).map((k) => Views[k]),
      messages: lang['ru'],
    }),
    []
  )

  const backgroundEvents = [
    {
      id: 1,
      title: 'Available for Clients',
      start: new Date(2024, 10, 17, 6),
      end: new Date(2024, 10, 18, 18),
    },
  ]

  const eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
    //console.log(event);
    var backgroundColor = '#' + event.hexColor;
    var borderColor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
    
    var style = {
      backgroundColor: event.color,
      //backgroundColor: 'white',
      borderRadius: '3px',
      opacity: 0.8,
      color: 'black',
      //border: '1px',
      //border: '2px solid ' + borderColor,
      //position: 'float',
      display: 'block'
    };
    return {
      style: style
    };
  }

  return (
    <div className='calendar'>
      <Calendar
        backgroundEvents={backgroundEvents}
        dayLayoutAlgorithm="no-overlap"

        components={components}
        defaultDate={defaultDate}
        culture={"ru"}
        showMultiDayTimes
        localizer={localizer}
        events={events}
        min={min}
        max={max}
        defaultView={Views.DAY}
        //startAccessor="start"
        //endAccessor="end"
        messages={messages}
        style={{ fontSize: '0.65rem', height: '50vw', minHeight: '600px', minWidth:'800px' }}
        //        style={{ fontSize: 'smaller' }}
        onSelectEvent={(event) => console.log("onSelectEvent", event)}
        onSelectSlot={(event) => console.log("onSelectSlot", event)}
        eventPropGetter={eventStyleGetter}
        //timeslots={6}
      />
    </div>
  )
})

export default MyCalendar;